<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$lang('title')}}</span>
			</div>

			<div v-loading="loading" class="ns-member-address-list">
				<el-form :model="formData" :rules="rules" ref="ruleForm" label-width="150px">
					<el-form-item :label="$lang('first_name')" prop="first_name">
						<el-input v-model="formData.first_name" :placeholder="$lang('first_placeholder')" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item :label="$lang('last_name')" prop="last_name">
						<el-input v-model="formData.last_name" :placeholder="$lang('last_placeholder')" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item :label="$lang('address')" prop="address">
                        <el-autocomplete
                            class="ns-len-input"
                            v-model="formData.address"
                            :fetch-suggestions="querySearchPlace"
                            :placeholder="$lang('address_placeholder')"
                            @select="handleSelectPlace"
                            autocomplete="off"
                        >
                        </el-autocomplete>
					</el-form-item>

					<el-form-item :label="$lang('city')">
						<el-input v-model="formData.city_name" autocomplete="off" placeholder="请输入收货人所在的区(Suburb)" class="ns-len-input"></el-input>
					</el-form-item>
					
					<el-form-item :label="$lang('country')" prop="full_address">
						<el-select v-model="formData.country_id" :placeholder="$lang('country_placeholder')" @change="changeCountry(formData.country_id)">
							<el-option v-for="option in countrys" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
						<el-select v-if="isProvinceList" v-model="formData.province_id" :placeholder="$lang('province_placeholder')" @change="changeNewProvice(formData.province_id)">
							<el-option v-for="option in province" :key="option.id" :label="option.name" :value="option.id">{{ option.name }}</el-option>
						</el-select>
					</el-form-item>

					<el-form-item :label="$lang('post_code')" prop="post_code">
						<el-input v-model.trim="formData.post_code" autocomplete="off" :placeholder="$lang('postcode_placeholder')" class="ns-len-input"></el-input>
					</el-form-item>
					
					<el-form-item :label="$lang('mobile')" prop="mobile">
						<el-input v-model="formData.mobile" autocomplete="off" :placeholder="$lang('mobile_placeholder')" class="ns-len-input"></el-input>
					</el-form-item>

					<el-form-item :label="$lang('is_default')">
						<el-radio-group v-model="formData.is_default">
							<el-radio :label="1">{{$lang('yes')}}</el-radio>
							<el-radio :label="0">{{$lang('no')}}</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" size="medium" @click="saveAddress('ruleForm')">{{$lang('save')}}</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
    import { addressInfo, saveAddress, googlePlace, placeDetail } from "@/api/member/member"
    import { getArea } from "@/api/address"

    export default {
        name: "address_edit",
        components: {},
        data() {
            let self = this
            var isMobile = (rule, value, callback) => {
                if (!value) {
                    return callback(new Error("手机号不能为空"))
                } else {
                    const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                    if (reg.test(value)) {
                        callback()
                    } else {
                        callback(new Error("请输入正确的手机号"))
                    }
                }
            }

            var fullAddress = (rule, value, callback) => {
                if (self.formData.country_id) {
                    if (self.formData.province_id) {
						return callback()
                    } else {
                        return callback(new Error(vue.$lang('region_tips')))
                    }
                } else {
                    return callback(new Error(vue.$lang('country_tips')))
                }
            }

            return {
                formData: {
                    id: 0,
                    name: "",
					first_name: "",
					last_name: "",
                    mobile: "",
                    telephone: "",
                    city_name: "",
                    post_code: "",
                    city_id: "",
                    district_id: "",
                    community_id: "",
                    country_id: "",//国家ID
                    province_id: "",//省份ID
                    address: "",
                    full_address: "",
                    latitude: 0,
                    longitude: 0,
                    is_default: 1,
                    country_name: "",
                    province_name: ""
                },
                addressValue: "",
                flag: false, //防重复标识
                defaultRegions: [],
                rules: {
                    first_name: [{ required: true, message: vue.$lang('first_name_tips'), trigger: "blur" }],
                    last_name: [{ required: true, message: vue.$lang('last_name_tips'), trigger: "blur" }],
                    post_code: [{ required: true, message: vue.$lang('post_code_tips'), trigger: "blur" }],
                    mobile: [{ required: true, message: vue.$lang('phone_tips'), trigger: "blur" }],
                    address: [{ required: true, message: vue.$lang('address_tips'), trigger: "blur" }],
                    full_address: [{ required: true, validator: fullAddress, trigger: "blur" }]
                },
				countrys: [],
				provinces: [],
                province: [],
                city: [],
                district: [],
                pickerValueArray: [],
                multiIndex: [0, 0, 0],
                isInitMultiArray: false,
                // 是否加载完默认地区
                isLoadDefaultAreas: false,
                loading: true,
				yes: true,
				isProvinceList: false,
                timeout: null,
                placeList: [],
                placekeyword: '',
            }
        },
        created() {
            this.formData.id = this.$route.query.id
            this.getAddressDetail()
			this.getNewAreas()
        },
		mounted() {
			let self = this;
			setTimeout(function() {
				self.yes = false
			}, 300)
		},
        watch: {
            defaultRegions: {
                handler(arr, oldArr = []) {
                    // 避免传的是字面量的时候重复触发
                    if (arr.length !== 2 || arr.join("") === oldArr.join("")) return
                },
                immediate: true
            },
			dateRange (val) {
				if(val.defaultRegions.length>0 && val.countrys.length>0){
					this.handleRegions()
				}
			}
        },
        computed: {
            pickedArr() {
                // 进行初始化
                if (this.isInitMultiArray) {
                    return [this.pickerValueArray[0], this.pickerValueArray[1], this.pickerValueArray[2]]
                }
                return [this.pickerValueArray[0], this.city, this.district]
            },
			dateRange () {
				const { defaultRegions, countrys } = this
				return {
					defaultRegions,
					countrys
				}
			}
        },
        methods: {
            querySearchPlace(queryString, cb) {
                if (this.placekeyword == queryString) {
                    cb(this.placeList);
                } else {
                    this.placekeyword = queryString
                    clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                        googlePlace({
                            keyword: queryString
                        }).then(res => {
                            if(res.data && res.data.length > 0) {
                                var results = res.data
                                this.placeList = results
                                cb(results);
                            } else {
                                var results = this.placeList
                                cb(results);
                            }
                        }).catch(err => {})
                    }, 2000 * Math.random());
                }
            },

            handleSelectPlace(item) {
                if (item.place_id) {
                    placeDetail({
                        place_id: item.place_id
                    }).then(res => {
                        var results = res.data
                        
                        if (results.address) {
                            this.formData.address = results.address;
                        }

                        if(results.city) {
                            this.formData.city_name = results.city;
                        }
                        if(results.country_id) {
                            this.formData.country_id = results.country_id;
                            this.formData.country_name = results.country_name;
                            this.isProvinceList = true;
                            this.province = this.provinces[results.country_id]
                        }

                        if(results.province_id) {
                            this.formData.province_id = results.province_id;
                            this.formData.province_name = results.province_name;
                        }

                        if (results.postal_code) {
                            this.formData.post_code = results.postal_code;
                        }
                    }).catch(err => {})

                } else {
                    if (item.address) {
                        this.formData.address = item.address;
                    }
                    if(item.city) {
                        this.formData.city_name = item.city;
                    }
                    if(item.country) {
                        this.formData.country_id = item.country;
                        this.formData.country_name = item.country_name;
                        this.isProvinceList = true;
                        this.province = this.provinces[item.country]
                    }

                    if(item.province) {
                        this.formData.province_id = item.province;
                        this.formData.province_name = item.province_name;
                    }
                }
            },

			//改变国家
			changeCountry(id){
				//查询国家是否有城市信息
				if(this.provinces[id]){
					this.province = this.provinces[id]
					//显示城市下拉框
					this.isProvinceList = true
					this.formData.province_id = ''
				}else{
					// 如果城市列表为空，就隐藏城市下拉框
					this.isProvinceList = false
					this.formData.province_id = ''
				}
				
				let obj = this.countrys.find(item => item.id === id )
                this.formData.country_name = obj.name
			},

			//改变省
			changeNewProvice(){
				let obj = this.province.find(item =>{
					return item.id === this.formData.province_id
				})
                this.formData.province_name = obj.name
			},
            
            /**
             * 获取地址信息
             */
            getAddressDetail() {
                addressInfo({
                    id: this.formData.id
                }).then(res => {
                    let data = res.data
                    if (data != null) {
                        this.formData.first_name = data.first_name
                        this.formData.last_name = data.last_name
                        this.formData.mobile = data.mobile
                        this.formData.city_name = data.city_name
                        this.formData.post_code = data.post_code
                        this.formData.address = data.address
                        this.formData.full_address = data.full_address
                        this.formData.latitude = data.latitude
                        this.formData.longitude = data.longitude
                        this.formData.is_default = data.is_default
                        this.formData.province_id = data.province_id
                        this.formData.city_id = data.city_id
                        this.formData.district_id = data.district_id
                        this.formData.country_name = data.country_name
                        this.formData.province_name = data.province_name
                        this.defaultRegions = [data.country_id, data.province_id]
                    }
                }).catch(err => {})
            },
            
            // 异步获取地区
            getAreas(pid, callback) {
                getArea({
                    pid: pid
                }).then(res => {
                    if (res.code == 0) {
                        var data = []
                        res.data.forEach((item, index) => {
                            data.push(item)
                        })
                        if (callback) callback(data)
                    }
                }).catch(err => {})
            },

            /**
             * 获取省市区列表
             */
            getDefaultAreas(pid, obj) {
                getArea({
                    pid: pid
                }).then(res => {
                    if (res.code == 0) {
                        var data = []
                        var selected = undefined
                        res.data.forEach((item, index) => {
                            if (obj != undefined) {
                                if (obj.level == 0 && obj.province_id != undefined) {
                                    selected = obj.province_id
                                } else if (obj.level == 1 && obj.city_id != undefined) {
                                    selected = obj.city_id
                                } else if (obj.level == 2 && obj.district_id != undefined) {
                                    selected = obj.district_id
                                }
                            }

                            if (selected == undefined && index == 0) {
                                selected = item.id
                            }
                            data.push(item)
                        })

                        this.pickerValueArray[obj.level] = data
                        if (obj.level + 1 < 3) {
                            obj.level++
                            this.getDefaultAreas(selected, obj)
                        } else {
                            this.isInitMultiArray = true
                            this.isLoadDefaultAreas = true
                        }

                        this.province = this.pickerValueArray[0]
                    }
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                }).catch(err => {
                    this.loading = false
                })
            },
			
			// 获取国家地区信息
			getNewAreas(){
				getArea().then(res => {
					// 国家列表
					this.countrys = res.data['1']
                    this.formData.country_id = this.countrys[0].id;
                    this.formData.country_name = this.countrys[0].name;
                    this.isProvinceList = true;

					// 省份列表
					this.provinces = res.data['2']
                    this.province = this.provinces[this.countrys[0].id]
					this.loading = false
				})
			},
			
			//渲染地址栏
			handleRegions(){
				if(this.countrys.length>0){
					// clog
					this.formData.country_id = this.defaultRegions[0]
					if(this.provinces[this.formData.country_id]){
						this.isProvinceList = true
						this.province = this.provinces[this.formData.country_id]
						this.formData.province_id = this.defaultRegions[1]
					}
				}
			},
			
            /**
             * 渲染默认值
             */
            handleDefaultRegions() {
                var time = setInterval(() => {
                    if (!this.isLoadDefaultAreas) return
                    this.isInitMultiArray = false

                    for (let i = 0; i < this.defaultRegions.length; i++) {
                        for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                            this.province = this.pickerValueArray[0]

                            // 匹配省
                            if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                                // 设置选中省
                                this.$set(this.multiIndex, i, j)
                                // 查询市
                                this.getAreas(this.pickerValueArray[i][j].id, data => {
                                    this.city = data

                                    for (let k = 0; k < this.city.length; k++) {
                                        if (this.defaultRegions[1] == this.city[k].id) {
                                            // 设置选中市
                                            this.$set(this.multiIndex, 1, k)

                                            // 查询区县
                                            this.getAreas(this.city[k].id, data => {
                                                this.district = data

                                                // 设置选中区县
                                                for (let u = 0; u < this.district.length; u++) {
                                                    if (this.defaultRegions[2] == this.district[u].id) {
                                                        this.$set(this.multiIndex, 2, u)
                                                        this.handleValueChange({
                                                            detail: {
                                                                value: [j, k, u]
                                                            }
                                                        })
                                                        break
                                                    }
                                                }
                                            })

                                            break
                                        }
                                    }
                                })
                            }
                        }
                    }
                    if (this.isLoadDefaultAreas) clearInterval(time)
                }, 100)
            },
            handleValueChange(e) {
                // 结构赋值
                let [index0, index1, index2] = e.detail.value
                let [arr0, arr1, arr2] = this.pickedArr
                let address = [arr0[index0], arr1[index1], arr2[index2]]

                this.formData.full_address = ""
                for (let i = 0; i < address.length; i++) {
                    if (this.formData.full_address) {
                        this.formData.full_address = this.formData.full_address + "-" + address[i].name
                    } else {
                        this.formData.full_address = this.formData.full_address + address[i].name
                    }
                }
            },

            /**
             * 保存地址
             */
            saveAddress(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var data = {
                            first_name: this.formData.first_name,
                            last_name: this.formData.last_name,
                            mobile: this.formData.mobile,
							telephone:this.formData.mobile,
                            post_code: this.formData.post_code,
                            country_id: this.formData.country_id,
                            province_id: this.formData.province_id,
                            city_name: this.formData.city_name,
                            country_name: this.formData.country_name,
                            province_name: this.formData.province_name,
                            district_id: this.formData.district_id,
                            community_id: "",
                            address: this.formData.address,
                            full_address: this.formData.full_address,
                            latitude: this.formData.latitude,
                            longitude: this.formData.longitude,
                            is_default: this.formData.is_default
                        }

                        data.url = "add"
                        if (this.formData.id) {
                            data.url = "edit"
                            data.id = this.formData.id
                        }
                        if (this.flag) return
                        this.flag = true

                        saveAddress(data).then(res => {
                            if (res.code == 0) {
                                this.$router.push({ path: "/member/delivery_address" })
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        }).catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                    } else {
                        return false
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
	.box {
		width: 100%;
		position: relative;
	}
	
	.null-page {
		width: 100%;
		height: 730px;
		background-color: #FFFFFF;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 9;
	}
	
    .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
        box-shadow: unset;
    }

    .el-card {
        border: 0;
    }

    .ns-len-input {
        width: 350px;
    }

    .el-select {
        margin-right: 10px;
    }
</style>
